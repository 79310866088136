import $ from 'jquery'
import { createMessenger } from '@userlike/messenger';

async function createApi() {
  const result = await createMessenger({
      version: 1,
      widgetKey: "cabb985451b1408086adc18fccb5cdc49e3965c6300043229c72d57ebf88e8f8",
  });
  const { api } = result.value;
  return api;
};

$(() => {
  if ( window.env == 'production') {
    const userlike = createApi();
    const showSupportButton = $('body#home').length > 0;

    userlike.then(function(messenger) {
      window.userlikeMessenger = messenger

      userlikeMessenger.mount().then(() =>
        userlikeMessenger.setVisibility({
          main: true,
          button: showSupportButton,
          notifications: true
        }).then(() => userlikeMessenger.setContactInfo(window.userlikeContactData))
      )
    });

    const startUserlikeChat = () => {
      userlike.then(m => m.maximize());
    }

    $('.support-chat-opener').click((event) =>
      startUserlikeChat()
    )
  }
})
